import { ReactNode, useEffect } from 'react';
import { SidebarMyAccount } from '@components/sidebar/SidebarMyAccount';
import { HeaderSidebar } from '@components/header_sidebar/headerSidebar';
import { useUser } from '@context/UserContext';
import { useCustomNavigate } from '@components/navigate/useCustomNavigate';

interface PrivateLayoutProps {
  children: ReactNode;
  headerItems: Array<{ text: string; link: string; isActive?: boolean }>;
}

export const PrivateLayout = ({ children, headerItems }: PrivateLayoutProps) => {
  const { isAuth, isLoading } = useUser();
  const customNavigate = useCustomNavigate();
  
  useEffect(() => {
    if(!isLoading)
      if (!isAuth) {
        customNavigate("/inloggen");
      }
  }, [isAuth, isLoading]);

  return (
    <div className="flex min-h-screen">
      <SidebarMyAccount />
      <div className="flex flex-col flex-1">
        <HeaderSidebar items={headerItems} />
        <div className="flex-1 p-8 pb-20 h-[calc(100vh-80px)] overflow-scroll max-w-6xl">
          {children}
        </div>
      </div>
    </div>
  );
}; 