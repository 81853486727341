import { useEffect, useRef, useState } from 'react';
import { DayPicker } from './DaySelect';
import { MonthPicker } from './MonthSelect';
import { YearPicker } from './YearSelect';
import { InputField, SmallButtonYellow } from 'component-library';
import { t } from 'i18next';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';
import { parseDate } from './DateUtils';

interface CustomDatePickerProps {
  date: string;
  updateDate: (date: string) => void;
  errorObj: any;
  fullWidth?: boolean; 
}

export const CustomDatePicker = ({ date, updateDate, errorObj, fullWidth = true }: CustomDatePickerProps) => {
  const [openDate, setOpenDate] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const initialDate = parseDate(date || 'geen geboortedatum');
  const [selectedDay, setSelectedDay] = useState<number>(initialDate.day || 1);
  const [selectedMonth, setSelectedMonth] = useState<string>(initialDate.month || 'januari');
  const [selectedYear, setSelectedYear] = useState<number>(initialDate.year || 2000);


  const formattedDisplayDate = `${selectedDay} ${selectedMonth} ${selectedYear}`;
  const containerClasses = fullWidth ? 'w-full' : 'w-80'; 
  const darkMode = false;

  const monthNames = [
    'januari', 'februari', 'maart', 'april', 'mei', 'juni',
    'juli', 'augustus', 'september', 'oktober', 'november', 'december',
  ];

  useEffect(() => {
    if (date) {
      const parsedDate = parseDate(date);
      if (parsedDate.day && parsedDate.month && parsedDate.year) {
        setSelectedDay(parsedDate.day);
        setSelectedMonth(parsedDate.month);
        setSelectedYear(parsedDate.year);
      }
    }
  }, [date]);

  const toggleOpen = () => {
    setOpenDate(!openDate);
  };

  useEffect(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      const monthIndex = monthNames.indexOf(selectedMonth) + 1;
      const formattedDate = `${selectedDay}-${String(monthIndex).padStart(2, '0')}-${selectedYear}`;
      updateDate(formattedDate);
    }
  }, [selectedDay, selectedMonth, selectedYear]);

  return (
    <div ref={modalRef} className={containerClasses}>
      <div
        className="relative"
        onClick={() => setOpenDate(!openDate)}
        onFocus={() => setOpenDate(true)}
        onBlur={() => setOpenDate(false)}
      >
        <InputField
          placeholder={t('dateOfBirth')}
          value={formattedDisplayDate}
          setState={() => {}}
          type="text"
          error={errorObj.date}
          handleUpdate={updateDate}
          itemKey="dateBirth"
          darkMode={darkMode}
          errorMsg={t('incorrectDateError')}
        />
        <div className="absolute right-0 top-6 cursor-pointer text-main-color" onClick={() => setOpenDate(!openDate)}>
          <CalendarDaysIcon className="h-6 w-6 " />
        </div>
      </div>
      {openDate && (
        <div className={`absolute w-full -ml-4 z-50 ${containerClasses} bg-white shadow-xl rounded p-4`}>
          <div className="w-full h-[250px]">
            <div className="flex flex-row">
              <div className="font-bold">SELECT A DATE</div>
              <div className="ml-auto w-fit text-sm text-main-color">
                {formattedDisplayDate}
              </div>
            </div>
            <div className="flex flex-row">
              <DayPicker selectedDay={selectedDay} onSelectDay={setSelectedDay} />
              <MonthPicker selectedMonth={selectedMonth} onSelectMonth={setSelectedMonth} />
              <YearPicker selectedYear={selectedYear} onSelectYear={setSelectedYear} />
            </div>
          </div>
          <div className="w-full h-12 bg-white">
            <SmallButtonYellow
              text="Bevestigen"
              onClick={toggleOpen}
              disabled={false}
              darkMode={darkMode}
            />
          </div>
        </div>
      )}
    </div>
  );
};