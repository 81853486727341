import { useState, useEffect, Key } from 'react';
import { Card } from '@components/modal/Card';
import { cancelSubscription } from '@components/api/API';
import individual from "@assets/individual-2024.jpg";
import { useNotification } from "@context/Notification/NotificationContext";
import { InputField, Slider, SmallButtonYellow } from "component-library";
import { PrivateLayout } from '@components/layout/PrivateLayout';
import { LoadingSkeleton } from '@components/skeleton/LoadingSkeleton';
import { useUser } from '@context/UserContext';
import { useCustomNavigate } from '@components/navigate/useCustomNavigate';

const headerItems = [
  {
    text: "Lidmaatschappen",
    link: "/subscriptions",
  },
];

export const MySubscriptions = () => {
  const { refreshSubscriptions, isLoading, isAuth, subscriptions } = useUser() as any;
  const [formattedSubscriptions, setFormattedSubscriptions] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const customNavigate = useCustomNavigate();
  const [selectedSubscription, setSelectedSubscription] = useState<any | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [refundToggle, setRefundToggle] = useState(false);
  const [data, setData] = useState({
    refundAmount: "",
    calculated: "",
    payment: "",
    busy: false,
  });
  const { notify } = useNotification();
  // let paidCardCount = 0; 

  useEffect(() => {
    if(!isLoading){
      if(isAuth){
        formatSubscriptions(subscriptions)
      } else {
        customNavigate('/inloggen')
      }
    }
  }, [isLoading, isAuth])

  // useEffect(() => {
  //   fetchUserSubscriptions();
  // }, []);

  const formatSubscriptions = async (subscriptions: any) => {
    const sortedSubscriptions = subscriptions.sort((a: any, b: any) => new Date(b.created).getTime() - new Date(a.created).getTime());
    const formattedSubscriptions = sortedSubscriptions.map((subscription: any) => ({
      ...subscription,
      title: getTitleLabel(subscription.name),
      validity: `${formatDate(subscription.startDate)} - ${formatDate(subscription.endDate)}`,
      purchasedDate: `${formatDate(subscription.created)}`,
      image: individual, 
      price: getPriceLabel(subscription),
      status: subscription.status,
      // paidCardCount: subscription.paidCardCount,
    }));
    setFormattedSubscriptions(formattedSubscriptions);
    setLoading(false);
  };

  const getPriceLabel = (subscription: any) => {
    // console.log('subscription', subscription)
    // const subscriptionName = subscription.name.toLowerCase();
    // paidCardCount++;

    // if (subscriptionName.includes("basis cjp")) {
    //   return "Gratis"; 
    // }
    // if (subscriptionName.includes("cultuurkaart") || subscriptionName.includes("jongerenpas")) {
    //   return paidCardCount === 1 ? "€10" : "€17,50";
    // }
    if(subscription.payment?.amount){
      return "€" + formatPrice(subscription.payment.amount)
    }
    return "---";
  };

  const formatPrice = (price: number) => {
    return price.toFixed(2).replace('.', ',')
  }

  const getTitleLabel = (subscriptionName: string) => {
    subscriptionName = subscriptionName.toLowerCase();
    if (subscriptionName.includes("basis cjp")) {
      return "Basis CJP";
    }
    if (subscriptionName.includes("cultuurkaart")) {
      return "Cultuurkaart";
    }
    if (subscriptionName.includes("jongerenpas")) {
      return "Jongerenpas";
    }
    return subscriptionName;
  }

  const formatDate = (date: string) => {
    const [year, month, day] = date.split("T")[0].split("-");
    return `${day}-${month}-${year}`;
  };

  const openPopup = (subscription: any) => {
    setSelectedSubscription(subscription);
    setData({
      refundAmount: subscription.refundAmount?.toFixed(2).replace('.', ',') || "",
      calculated: subscription.refundAmount?.toFixed(2).replace('.', ',') || "",
      payment: subscription.__molliePayment__ ? parseFloat(subscription.__molliePayment__?.amount).toFixed(2).replace('.', ',') : "17,50",
      busy: false,
    });
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedSubscription(null);
  };

  const setRefundToggleAndValue = (toggle: boolean) => {
    setRefundToggle(toggle);
    if (toggle) {
      setData({
        ...data,
        refundAmount: data.payment,
      });
    } else {
      setData({
        ...data,
        refundAmount: data.calculated,
      });
    }
  };

  const handleChange = (value: string, key: string) => {
    setData({
      ...data,
      [key]: value
    });
  };

  const handleConfirm = async () => {
    setData({ ...data, busy: true });
    const response = await cancelSubscription(selectedSubscription.uuid, {
      refundAmount: parseFloat(data.refundAmount.replace(',', '.')),
    });
    if (response.status === 200) {
      notify("Abonnement is geannuleerd.", "success");
      setData({ ...data, busy: false });
      closePopup();
      refreshSubscriptions();
    } else {
      notify("Er is een fout opgetreden.", "error");
      setData({ ...data, busy: false });
    }
  };

  const checkDisabled = () => {
    return !selectedSubscription || data.busy;
  };

  const isFutureDate = (isoDateString: string): boolean => { 
    const suppliedDate = new Date(isoDateString); 
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); 
    if (isNaN(suppliedDate.getTime())) {
      throw new Error('Invalid date format. Please use a valid ISO 8601 date string.');
    } 
    const suppliedDateOnly = new Date(suppliedDate);
    suppliedDateOnly.setHours(0, 0, 0, 0); 
    return suppliedDateOnly.getTime() > currentDate.getTime();
  };

  const findIndividualSubscription = (subscription: any) => { 
    if(subscription.name.includes('Jongerenpas') || subscription.name.includes('Individual')){
      if(isFutureDate(subscription.endDate) && subscription.autoRenew) return true 
    }
    return false
  }

  useEffect(() => {
    document.title = "CJP | Lidmaatschappen";
    return () => {
      document.title = "CJP";
    };
  }, []);

  return (
    <PrivateLayout headerItems={headerItems}>
      <div className="flex ">
        <div className="flex flex-col flex-1">
          <div className="flex-1 pb-20">
            <div className="font-bold font-formula text-2xl mt-2 tracking-wide">
              Abonnement geschiedenis
            </div>
            <div className="grid mt-5 grid-cols-1 md:grid-cols-2 gap-6">
              {loading ? (
                Array.from({ length: 4 }).map((_, index) => (
                  <LoadingSkeleton 
                    key={index}
                    isLoading={true}
                    width="w-full"
                    height="h-60 md:h-36"
                    containerHeight="h-60 md:h-36"
                    topPadding="mt-0"
                  >
                    <div />
                  </LoadingSkeleton>
                ))
              ) : formattedSubscriptions.length > 0 ? (
                formattedSubscriptions.map((subscription: any, index: Key | null | undefined) => (
                  <Card
                    key={index}
                    title={subscription.title}
                    validity={subscription.validity}
                    purchasedDate={subscription.purchasedDate}
                    image={subscription.image}
                    price={subscription.price}
                    status={subscription.status}
                    id={0}             
                    disabled={!findIndividualSubscription(subscription)}
                  />
                ))
              ) : (
                <div className="text-gray-500 text-center col-span-full">
                  Geen actieve abonnementen
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <div className="fixed inset-0 z-[1000] w-screen h-screen grid place-content-center">
          <div className="absolute w-screen h-screen bg-black/40 z-[100] backdrop-blur"></div>
          <div className="w-full md:w-[500px] min-h-[250px] flex flex-col rounded-lg bg-white relative z-[110] p-6">
            <div className="text-xl font-bold text-main-blue pb-3">
              Abonnement opzeggen
            </div>
            <div className="flex-1">
              Let op: Het opzeggen van het abonnement zorgt er voor dat de gebruiker geen toegang meer heeft tot de CJP kortingen en aanbiedingen. Eventuele restitutie wordt automatisch terug gestort op de rekening van de gebruiker.
              <br/><br/>
              In het tekstveld is het huidige restitutiebedrag voor de gebruiker ingevuld voor opzegging per vandaag. Dit bedrag kan aangepast worden. Gebruik de schakelaar om te schakelen tussen het resitutiebedrag of een volledige terugbetaling.
              <div className="flex flex-col gap-y-4 pb-8 mt-2">
                <div className="flex">
                  <div className="w-fit">
                    Volledige terugbetaling:
                  </div> 
                  <div className="w-fit ml-3">
                    <Slider
                      value={refundToggle}
                      onClick={() => setRefundToggleAndValue(!refundToggle)}
                      text={""}
                    />
                  </div>
                </div>
                <InputField
                  placeholder="Restitutie bedrag"
                  value={data.refundAmount}
                  type="text"
                  handleUpdate={handleChange}
                  itemKey="refundAmount" 
                />
                { selectedSubscription?.isRenewal === true ? "Abonnement is een verlenging, restitutie automatisch berekend." : "Dit is het eerste jaarlidmaatschap, restitutie wordt standaard niet gegeven." }
              </div>
            </div>
            <div className="flex flex-row justify-between gap-x-5"> 
              <SmallButtonYellow
                text={"Annuleren"}
                onClick={closePopup}
                disabled={false} 
                inverted={true}
              />
              <SmallButtonYellow
                text={"Bevestigen"}
                onClick={handleConfirm}
                disabled={checkDisabled()} 
              />
            </div>
          </div>
        </div>
      )}
    </PrivateLayout>
  );
};