import React, { useEffect, useState } from "react";
// import { SmallButtonYellow } from "component-library";
import logo from "@assets/logo.svg";

interface CardProps {
  id: number;
  title: string;
  location?: string;
  validity: string;
  purchasedDate: string;
  image: string;
  price: string;
  onButtonClick?: () => void;
  status?: string;
  button?: string;
  disabled?: boolean;
  statusSelector?: React.ReactNode;
}

export const Card: React.FC<CardProps> = ({
  id,
  title,
  location,
  validity,
  purchasedDate,
  image,
  price,
  onButtonClick,
  status,
  button,
  disabled,
  statusSelector,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [imgError, setImgError] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const getStatusLabel = (status: string) =>
    status === "active" ? "Actief" : "Afgelopen";
  const getStatusColor = (status: string) =>
    status === "active" ? "text-green-600" : "text-red-600";

  return (
    <div className="flex flex-col w-full bg-gray-100 p-6 rounded-lg">
      <div className="md:flex md:space-x-4">
        <div className="md:w-1/3 flex justify-center items-center rounded-lg overflow-hidden relative h-60 md:h-36">
          {imgError || !image ? (
            <img
              src={logo}
              alt="logo"
              className="h-20 w-20 object-contain absolute"
            />
          ) : (
            <img
              src={image}
              alt={title}
              className="w-full h-full object-cover rounded-lg"
              onError={() => setImgError(true)}
            />
          )}
        </div>

        <div className="flex flex-col w-full mt-4 md:mt-0 relative">
          <h2 className="text-lg font-medium text-center md:hidden">{title}</h2>
          <h2 className="text-lg font-medium ml-4 text-left hidden md:block">{title}</h2>

          {status && (
            <p className={`ml-4 text-lg text-center md:!text-left ${getStatusColor(status)}`}>
              {getStatusLabel(status)}
            </p>
          )}

          <p className="text-sm text-gray-600 ml-0 md:ml-4 text-center md:!text-left">
            {location}
          </p>
          <p className="text-sm text-gray-600 ml-4 text-center md:!text-left">
            Gekocht op: {purchasedDate}
          </p>
          <p className="text-sm text-gray-600 ml-4 text-center md:!text-left">
            Beschikbaarheid: {validity}
          </p>
          <p className="text-sm text-gray-600 ml-4 text-center md:!text-left">
            Prijs: {price}
          </p>
        </div>
      </div>

      <div className="mt-4 sm:w-full md:w-auto mx-auto">
        <div className="flex flex-row justify-between items-center w-full">
          {!isMobile && statusSelector && (
            <div className="mr-8">{statusSelector}</div>
          )}

          {/* <SmallButtonYellow
            text={button}
            onClick={onButtonClick}
            disabled={disabled}
          /> */}
        </div>

        {isMobile && statusSelector && (
          <div className="flex flex-col mt-6 justify-between items-center">
            <div className="flex justify-center w-full">{statusSelector}</div>
          </div>
        )}
        
      </div>
    </div>
  );
};