import React, { useEffect, useState } from "react";
// import Logo from "@assets/Logo.png";
import logo from "@assets/logo.svg";
import { LinkItemMyAccount } from "./LinkItemMyAccount";
import { Bars3CenterLeftIcon, Bars3Icon, XMarkIcon,GlobeAltIcon, CreditCardIcon, ReceiptPercentIcon, UsersIcon, FolderIcon,  XCircleIcon } from "@heroicons/react/24/outline";
import { useUser } from "@context/UserContext";
import { useNavigate } from "react-router-dom";
import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/20/solid";

export const SidebarMyAccount: React.FC = () => {
  const [collapseSidebar, setCollapseSidebar] = useState(window.innerWidth >= 1200)
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleCollapseSidebar = () => {
    const newState = !collapseSidebar;
    setCollapseSidebar(newState);
    localStorage.setItem('collapseSidebar', newState.toString());
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const { handleLogout } = useUser();
  const navigate = useNavigate();  

  const handleClickedLogOut = async () => {
    try {
      if (handleLogout) {
        await handleLogout(); 
        console.log("Logout successful");
        navigate("/inloggen");
        setIsMenuOpen(false); 
      }
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  const sidebarMyAccountLinks = [
    {
      icon: <GlobeAltIcon className="w-full h-full" />,
      text: "Bekijk al het aanbod",
      link: "https://www.cjp.nl/",
      external: true, 
    },
    {
      icon: <CreditCardIcon className="w-full h-full" />,
      text: "Mijn CJP",
      link: "/my-account",
      external: false,
    },
    {
      icon: <UsersIcon className="w-full h-full" />,
      text: "Mijn profiel",
      link: ["/my-profile/my-details", "/my-profile/instellingen"],
      external: false,
    },
    {
      icon: <FolderIcon className="w-full h-full" />,
      text: "Mijn lidmaatschappen",
      link: "/subscriptions",
      external: false,
    },
    {
      icon: <ReceiptPercentIcon className="w-full h-full" />,
      text: "Mijn bestellingen",
      link: ["/my-deals/active","/my-deals/inactive", "/my-deals/ticket/"],
      external: false,
    }  
  ];

  useEffect(() => {
    const checkScreenWidth = () => {
      if (window.innerWidth < 1200) {
        setCollapseSidebar(false);
      } else {
        setCollapseSidebar(true);
      }
    };

    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const handleLogoClick = () => {
    navigate("/my-account");
  };

  return (
    <div className="select-none">
      {/* Burger icon for mobile screens */}
      <div className="md:hidden fixed top-6 right-4 z-[9999] select-none">
        <button onClick={toggleMenu}>
          {isMenuOpen ? (
            <XMarkIcon className="w-8 h-8 text-white" />
          ) : (
            <Bars3Icon className="w-8 h-8 text-white" />
          )}
        </button>
      </div>

      {/* Side menu for larger screens */}
      <div
        className={`hidden md:flex flex-col min-h-screen bg-black text-white transition-all duration-50 ${
          collapseSidebar ? "w-[275px]" : "w-[100px]"
        } sticky top-0 shadow-lg`}
      >
        <div className="mt-8 ml-6 transition-all duration-50"
          // className={`min-h-[80px] max-h-[80px] items-center ${
          //   collapseSidebar ? "flex flex-row pl-3" : "flex flex-col ml-0 pt-4"
          // } w-full bg-black`}
        >
          <img
            src={logo}
            alt="logo"
            className={`${
              collapseSidebar ? "h-[24px]" : "h-[24px]"
            } filter invert brightness-0 cursor-pointer`}
            onClick={handleLogoClick} 
          />
        </div>
            
        <div className="flex-1 bg-black text-white pt-6 overflow-auto pb-12 flex flex-col justify-between">
          <div className="my-4 flex cursor-pointer hover:text-main-color transition-all duration-50" onClick={() => toggleCollapseSidebar()}>
            <div className={`${collapseSidebar || isMenuOpen ? "ml-6 w-6 h-6" : "ml-8 w-8 h-8"} flex-shrink-0 flex justify-center`}>
              {collapseSidebar ? 
                <Bars3Icon className="w-full h-full" /> 
              :
                <Bars3CenterLeftIcon className="w-full h-full" />
              }
            </div>
            {(collapseSidebar) && (
              <div className="ml-5 flex-1 text-left">
                {"Menu"} 
              </div>
            )}
          </div>

          {sidebarMyAccountLinks.map((item, index) => (
            <LinkItemMyAccount
              key={index}
              icon={item.icon}
              text={item.text}
              link={item.link}
              collapseSidebar={collapseSidebar}
              external={item.external}
              isMenuOpen={isMenuOpen}
            />
          ))}
          {/* <div className="mt-auto flex cursor-pointer" onClick={() => handleClickedLogOut()}>
            <div className={`${collapseSidebar || isMenuOpen ? "ml-4 w-6 h-6" : "w-7 h-7 m-auto pr-2"} flex-shrink-0 flex justify-center`}>
              <XCircleIcon className="w-full h-full" /> 
            </div>
            {(collapseSidebar || isMenuOpen) && (
              <div className="ml-5 flex-1 text-left">
                {"Uitloggen"} 
              </div>
            )}
          </div> */}
          <div className="mt-auto flex cursor-pointer" onClick={() => handleClickedLogOut()}>
            <div className={`${collapseSidebar || isMenuOpen ? "ml-6 w-6 h-6" : "ml-8 w-8 h-8"} transition-all duration-50 flex-shrink-0 flex justify-center`}>
              <ArrowRightStartOnRectangleIcon className="w-full h-full" /> 
            </div>
            {(collapseSidebar || isMenuOpen) && (
              <div className="ml-5 flex-1 text-left">
                {"Uitloggen"} 
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Menu overlay for mobile screens */}
      <div
        className={`md:hidden fixed inset-0 bg-black bg-opacity-90 z-[9998] transform transition-transform duration-300 ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col justify-center h-full text-white">
          <img 
            src={logo} 
            alt="logo" 
            className="h-[60px] mb-8 invert items-center brightness-0"
            onClick={() => {
              navigate("/my-account");
              setIsMenuOpen(false); 
            }} 
          />
          <div className="mx-auto">
            {sidebarMyAccountLinks.map((item, index) => (
              <LinkItemMyAccount
                key={index}
                icon={item.icon}
                text={item.text}
                link={item.link}
                collapseSidebar={collapseSidebar}
                external={item.external}
                isMenuOpen={isMenuOpen}
              />
            ))}
            <div className="mt-3 flex cursor-pointer" onClick={() => handleClickedLogOut()}>
            <div className={`${collapseSidebar || isMenuOpen ? "ml-4 w-6 h-6" : "w-7 h-7 m-auto pr-2"} flex-shrink-0 flex justify-center`}>
              <ArrowRightStartOnRectangleIcon className="w-full h-full" /> 
            </div>
            {(collapseSidebar || isMenuOpen) && (
              <div className="ml-5 flex-1 text-left">
                {"Uitloggen"} 
              </div>
            )}
          </div>
          </div>

          {/* Mobile-only Download Buttons */}
          <div className="flex flex-col items-center justify-center space-y-12 mt-12">
            {/* App Store Button */}
            <a
              href="https://apps.apple.com/us/app/cjp/id6449544194"
              target="cjp app"
              className="flex items-center justify-center w-56 gap-x-4 py-2 bg-main-color text-white rounded-md hover:bg-blue-100 transition"
            >
             
                {/* Apple logo SVG */}
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-apple" viewBox="0 0 16 16">
                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
              </svg>       
              <span className="">
                <span className="text-sm">Download van</span> <br/>
                <span className="font-bold">App Store</span>
              </span>  
            </a>

            {/* Google Play Button */}
            <a
              href="https://play.google.com/store/apps/details?id=nl.cjp.app"
              target="cjp app"
              className="flex items-center justify-center w-56 gap-x-4 p-2 bg-main-color text-white rounded-md hover:bg-blue-100 transition"
            >
                {/* Google Play logo SVG */}
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 256 283">
                <path fill="#ea4335" d="M119.553 134.916L1.06 259.061a32.14 32.14 0 0 0 47.062 19.071l133.327-75.934z"/>
                <path fill="#fbbc04" d="M239.37 113.814L181.715 80.79l-64.898 56.95l65.162 64.28l57.216-32.67a31.345 31.345 0 0 0 0-55.537z"/>
                <path fill="#4285f4" d="M1.06 23.487A30.6 30.6 0 0 0 0 31.61v219.327a32.3 32.3 0 0 0 1.06 8.124l122.555-120.966z"/>
                <path fill="#34a853" d="m120.436 141.274l61.278-60.483L48.564 4.503A32.85 32.85 0 0 0 32.051 0C17.644-.028 4.978 9.534 1.06 23.399z"/>
              </svg>         
              <span className="">
                <span className="text-sm">Download van</span> <br/>
                <span className="font-bold">Google Play</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
