import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card } from '@components/modal/Card';
import { getOrders } from '@components/api/API';
import { PrivateLayout } from '@components/layout/PrivateLayout';
import { LoadingSkeleton } from '@components/skeleton/LoadingSkeleton';

const headerItems = [
  {
    text: "Mijn aanbiedingen",
    link: "/my-deals/active",
  },
];

export const MyDeals: React.FC = () => {
  interface Deal {
    id: number;
    title: string;
    location: string;
    validity: string;
    purchasedDate: string;
    price: string;
    image: string;
  }
  const [inactiveDeals, setInactiveDeals] = useState<Deal[]>([]);
  const [activeDeals, setActiveDeals] = useState<Deal[]>([]);
  const [userStatuses, setUserStatuses] = useState<Record<number, string>>({});
  const [notification, setNotification] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // const tempData = getDeals();

  const handleOpenTicket = (dealId: number) => {
    const context = location.pathname === "/my-deals/active" ? "active" : "inactive";
    
    console.log("Current path:", location.pathname); 
    console.log("Determined context:", context); 
  
    navigate(`/my-deals/ticket/${dealId}?context=${context}`);
  };
  
  const fetchDeals = async (inputData: any) => {
    let data = inputData;
    // let data = tempData;
    // if (inputData.length > 0) {
    //   data = inputData;
    // }
    try {
      // const formattedDeals = data.map((deal) => ({
      const formattedDeals = data.map((deal: any) => ({
        id: deal.id,
        title: deal.product?.name,
        location:
          typeof deal.product?.location === "string"
            ? deal.product.location
            : deal.product.location?.coordinates
            ? `${deal.product.location.coordinates[0]}, ${deal.product.location.coordinates[1]}`
            : "Unknown location",
        validity: deal.product?.status === "published" ? "Always" : "Not available",
        purchasedDate: new Date(deal.created_at).toLocaleDateString("nl-NL"),
        price: `€${deal.lines[0]?.price}`,
        image: deal.product?.images?.desktop[0] || "",
      }));

      const savedStatuses = JSON.parse(localStorage.getItem("userStatuses") || "{}");
      // const initialStatuses = formattedDeals.map((deal) => ({
      const initialStatuses = formattedDeals.map((deal: any) => ({
        id: deal.id,
        status: savedStatuses[deal.id] || "active", 
      }));

      const active = formattedDeals.filter(
        // (deal) => initialStatuses.find((status) => status.id === deal.id)?.status === "active"
        (deal: any) => initialStatuses.find((status: { id: number; status: string }) => status.id === deal.id)?.status === "active"
      );
      const inactive = formattedDeals.filter(
        // (deal) => initialStatuses.find((status) => status.id === deal.id)?.status === "not interesting"
        (deal: any) => initialStatuses.find((status: { id: number; status: string }) => status.id === deal.id)?.status === "not interesting"
      );

      setActiveDeals(active);
      setInactiveDeals(inactive);
      // setUserStatuses(Object.fromEntries(initialStatuses.map(({ id, status }) => [id, status])));
      setUserStatuses(Object.fromEntries(initialStatuses.map(({ id, status }: { id: number; status: string }) => [id, status])));
    } catch (error) {
      console.error("Error formatting deals", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetAllOrders = async () => {
    try {
      const res = await getOrders();
      if (res && res.status === 200) {
        fetchDeals(res.data);
      } else {
        fetchDeals([]);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      fetchDeals([]);
    }
  };

  const handleStatusChange = (id: number, newStatus: string) => {
    setUserStatuses((prevStatuses) => {
      const updatedStatuses = { ...prevStatuses, [id]: newStatus };
      localStorage.setItem("userStatuses", JSON.stringify(updatedStatuses)); 

      let movedTo = "";

      if (newStatus === "not interesting" || newStatus === "used") {
        const movedDeal = activeDeals.find((deal) => deal.id === id);
        if (movedDeal) {
          setInactiveDeals((prev) => [...prev, movedDeal]);
          setActiveDeals((prev) => prev.filter((deal) => deal.id !== id));
          movedTo = "Inactief";
        }
      } else if (newStatus === "active") {
        const movedDeal = inactiveDeals.find((deal) => deal.id === id);
        if (movedDeal) {
          setActiveDeals((prev) => [...prev, movedDeal]);
          setInactiveDeals((prev) => prev.filter((deal) => deal.id !== id));
          movedTo = "Actief";
        }
      }

      if (movedTo) {
        setNotification(`Kaart verplaatst naar ${movedTo} tabblad.`);
        setTimeout(() => setNotification(null), 3000);
      }

      return updatedStatuses;
    });
  };

  useEffect(() => {
    handleGetAllOrders();
  }, []);

  const currentDeals = location.pathname.includes("/active")
    ? activeDeals
    : location.pathname.includes("/inactive")
    ? inactiveDeals
    : [];

  const renderActionButton = (dealId: number) => {
    const currentStatus = userStatuses[dealId];
    if (currentStatus === "active") {
      return (
        <button
          className="text-gray-500 underline"
          onClick={() => handleStatusChange(dealId, "not interesting")}
        >
          Archiveren
        </button>
      );
    }
    return (
      <button
        className="text-gray-500 underline"
        onClick={() => handleStatusChange(dealId, "active")}
      >
        Activeren
      </button>
    );
  };

  useEffect(() => {
    document.title = "CJP | Mijn deals";
    return () => {
      document.title = "CJP";
    };
  }, []);

  return (
    <PrivateLayout headerItems={headerItems}>
      <div className="font-bold font-formula pt-2 text-2xl tracking-wide">
        Mijn deals
      </div>
      {notification && (
        <div className="fixed top-14 z-[9999] right-4 md:right-8 md:w-1/3 w-1/2 p-4 text-white bg-green-600 rounded-md">
          {notification}
        </div>
      )}
      <div className="grid mt-5 grid-cols-1 md:grid-cols-2 gap-6">
        {loading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <LoadingSkeleton 
              key={index} 
              isLoading={true}
              width="w-full"
              height="h-60 md:h-36"
              containerHeight="h-60 md:h-36"
              topPadding="mt-0"
            >
              <div />
            </LoadingSkeleton>
          ))
        ) : currentDeals.length > 0 ? (
          currentDeals.map((deal) => (
            <Card
              id={deal.id}
              key={deal.id}
              title={deal.title}
              location={deal.location}
              validity={deal.validity}
              purchasedDate={deal.purchasedDate}
              price={deal.price}
              image={deal.image}
              onButtonClick={() => handleOpenTicket(deal.id)}
              statusSelector={
                <div className="flex space-x-4">
                  {renderActionButton(deal.id)}
                </div>
              }
              button="Open jouw ticket"
            />
          ))
        ) : (
          <div className="text-gray-500 text-center col-span-full">
            Geen deals beschikbaar
          </div>
        )}
      </div>
    </PrivateLayout>
  );
};