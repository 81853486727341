import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Barcode from 'react-barcode';
import parse from 'html-react-parser'; 
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { QRCodeSVG } from 'qrcode.react';
import { SidebarMyAccount } from '@components/sidebar/SidebarMyAccount';
import { HeaderSidebar } from '@components/header_sidebar/headerSidebar';
import { getDeals } from './getDeals';
import { SmallButtonYellow } from 'component-library';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import logo from "@assets/logo.svg";

export const MyTicketPage: React.FC = () => {
  const { dealId } = useParams<{ dealId: string }>();
  const [deal, setDeal] = useState<any | null>(null);
  const [currentCodeIndex, setCurrentCodeIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const context = queryParams.get('context') || 'active'; 

  useEffect(() => {
    const fetchDeal = async () => {
      try {
        const deals = await getDeals();
        const selectedDeal = deals.find((d) => d.id === Number(dealId));

        if (!selectedDeal) {
          console.error("Deal not found");
          setDeal(null);
          return;
        }

        const formattedDeal = {
          ...selectedDeal,
          location:
            typeof selectedDeal.product.location === "string"
              ? selectedDeal.product.location
              : selectedDeal.product.location?.coordinates
              ? `${selectedDeal.product.location.coordinates[0]}, ${selectedDeal.product.location.coordinates[1]}`
              : "Unknown location",
        };
        setDeal(formattedDeal);
      } catch (error) {
        console.error("Error fetching deal:", error);
        setDeal(null);
      } finally {
        setLoading(false);
      }
    };

    if (dealId) {
      fetchDeal();
    }
  }, [dealId]);

  const ItemHeaderSidebar = [
    {
      text: 'Actief',
      link: '/my-deals/active',
      isActive: context === 'active',
    },
    {
      text: 'Inactief',
      link: '/my-deals/inactive',
      isActive: context === 'inactive',
    },
  ];
  
  if (loading) {
    return (
      <div className="flex min-h-screen bg-white">
        <SidebarMyAccount />
        <div className="flex flex-col flex-1">
          <HeaderSidebar items={ItemHeaderSidebar} />
          <div className="flex justify-center items-center md:py-8">
            <div className="flex flex-col bg-blue-50 rounded-lg shadow-lg max-w-6xl w-full p-10 space-y-6">
              <Skeleton height={40} width={200} />
              <Skeleton height={200} />
              <Skeleton height={40} width={200} />
              <Skeleton height={40} width={200} />
              <Skeleton height={40} width={200} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!deal) {
    return <div>Bezig met laden of geen gegevens beschikbaar</div>;
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); 
    const month = String(date.getMonth() + 1).padStart(2, "0"); 
    const year = date.getFullYear();
    return `${day}-${month}-${year}`; 
  };  

  const copyToClipboard = async (text: string) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';
        textArea.style.left = '-9999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        const successful = document.execCommand('copy');
        document.body.removeChild(textArea);
        if (!successful) {
          throw new Error('Failed to copy text');
        }
      }
      return true;
    } catch (error) {
      console.error('Failed to copy text to clipboard:', error);
      return false;
    }
  };

  const renderCode = (deal: any, currentCodeIndex: number) => {
    const currentLine = deal.lines[currentCodeIndex];
  
    if (deal.order_provider_type === "ticket") {
      if (currentLine?.code && currentLine?.code_type === "QR") {
        return (
          <QRCodeSVG
            value={currentLine.code}
            size={window.innerWidth < 640 ? 200 : 120}
          />
        );
      } else if (currentLine?.code) {
        return (
          <Barcode
            value={currentLine.code}
            width={2}
            height={40}
            background="transparent"
            lineColor="#000000"
          />
        );
      } else {
        return <p>Geen streepjescode beschikbaar</p>;
      }
    } else if (deal.order_provider_type === "voucher") {
      const voucherCode = currentLine?.external_id
        ? currentLine.external_id.split('/').pop() 
        : null;
  
      return voucherCode ? (
        <div className="flex items-center space-x-2">
          <p className="text-sm cursor-pointer" onClick={() => copyToClipboard(voucherCode)}>
            Vouchercode: <span className="font-bold text-2xl text-main-color">{voucherCode}</span>
          </p>
          <ClipboardDocumentIcon
            className="w-5 h-5 text-gray-500 cursor-pointer"
            onClick={() => copyToClipboard(voucherCode)}
          />
        </div>
      ) : (
        <p>Geen vouchercode beschikbaar</p>
      );
    }
    return <p>Ongeldig dealtype</p>;
  };  

  const handlePrevious = () => {
    setCurrentCodeIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentCodeIndex((prevIndex) =>
      Math.min(prevIndex + 1, deal.lines.length - 1)
    );
  };

  const openModal = () => {
    if (deal.order_provider_type !== "voucher") {
      setIsModalOpen(true);
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`; 
    }
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  };

  const descriptionText = deal.order_provider_type === "voucher"
    ? parse(deal.product.confirmation_text)
    : parse(deal.product.description);
  
  return (
    <div className="flex min-h-screen bg-white">
      <SidebarMyAccount />
      <div className="flex flex-col flex-1">
        <HeaderSidebar items={ItemHeaderSidebar} />
        <div className="flex justify-center items-center md:py-8">
          <div className="flex flex-col bg-blue-50 rounded-lg shadow-lg max-w-6xl w-full p-10 space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full">
              <div className="flex-shrink-0">
                <div className="justify-center mb-8 md:hidden flex justify-center items-center">
                  
                  <div className="text-center justify-center items-center flex flex-col">
                    <div className="rounded-lg cursor-pointer md:hidden" onClick={openModal}>
                      {renderCode(deal, currentCodeIndex)}
                    </div>
                    {deal.lines.length > 1 && (
                      <div className="flex items-center gap-3 mt-4 justify-center md:hidden">
                        <button
                          onClick={handlePrevious}
                          disabled={currentCodeIndex === 0}
                          className={`px-2 py-1 rounded-lg md:hidden ${
                            currentCodeIndex === 0
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-main-color text-white"
                          }`}
                        >
                          ←
                        </button>
                        <p className="text-sm md:hidden">
                          Code {currentCodeIndex + 1}/{deal.lines.length}
                        </p>
                        <button
                          onClick={handleNext}
                          disabled={currentCodeIndex === deal.lines.length - 1}
                          className={`px-2 py-1 rounded-lg md:hidden ${
                            currentCodeIndex === deal.lines.length - 1
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-main-color text-white"
                          }`}
                        >
                          →
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-2xl text-center md:hidden font-semibold text-gray-800 mb-4">
                  {deal.product.name}
                </div>
                <div className="text-2xl hidden md:block font-semibold text-gray-800 mb-2 -mt-12">
                  {deal.product.name}
                </div>
                <div className="flex justify-center items-center md:hidden">
                  <img
                    src={deal.product.images.desktop[0] || logo}
                    alt={deal.product.name}
                    className="rounded-lg w-30 h-full object-cover max-h-80 md:hidden"
                    onError={(e) => (e.currentTarget.src = logo)}
                  />
                </div>
                <div className="hidden md:block">
                  <img
                    src={deal.product.images.desktop[0] || logo}
                    alt={deal.product.name}
                    className="rounded-lg w-30 h-full object-cover max-h-80"
                    onError={(e) => (e.currentTarget.src = logo)}
                  />
                </div>
              </div>
              <div className="flex-col flex mt-5 basis-2/3 gap-4 w-50">
                <div className="flex-col flex ">
                  <div className="rounded-md text-center md:!text-left">
                    <p className="text-sm">Gekocht <span className='font-bold'>{new Date(deal.created_at).toLocaleDateString("nl-NL")}</span></p>
                  </div>
                  <div className="rounded-md text-center md:!text-left">
                    <p className="text-sm">Bestel ID <span className='font-bold'>{deal.id}</span></p>
                  </div>
                  <div className="rounded-md text-center md:!text-left">
                    <p className="text-sm">Prijs €<span className='font-bold'>{deal.lines[0].price}</span></p>
                  </div>
                  <div className="rounded-md text-center md:!text-left">
                    <p className="text-sm">Locaties <span className='font-bold'>{deal.location || "Multiple"}</span></p>
                  </div>
                  <div className="rounded-md col-span-2 text-center md:!text-left">
                    <p className="text-sm">
                      Geldigheid <span className='font-bold'>{deal.product.ends_at ? formatDate(deal.product.ends_at) : "No end date"}</span>
                    </p>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="flex ml-4 justify-start" onClick={openModal}>
                    {renderCode(deal, currentCodeIndex)}
                  </div>
                  {deal.lines.length > 1 && (
                    <div className="flex items-center justify-start gap-3 mt-4">
                      <button
                        onClick={handlePrevious}
                        disabled={currentCodeIndex === 0}
                        className={`px-4 py-2 rounded-lg ${
                          currentCodeIndex === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-main-color text-white'
                        }`}
                      >
                        ←
                      </button>
                      <p className="text-sm">
                        Code {currentCodeIndex + 1}/{deal.lines.length}
                      </p>
                      <button
                        onClick={handleNext}
                        disabled={currentCodeIndex === deal.lines.length - 1}
                        className={`px-4 py-2 rounded-lg ${
                          currentCodeIndex === deal.lines.length - 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-main-color text-white'
                        }`}
                      >
                        →
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-grow text-sm md:pt-4 text-gray-700">
                <p className="font-semibold text-center md:!text-left">Goed om te weten:</p>
                <ul className="list-disc text-center md:!text-left">{descriptionText}</ul>
              </div>
            </div>
            <div className="flex justify-center md:pt-8">
              <div className="w-56 mb-36 md:mb-0">
                <SmallButtonYellow
                  text="Download jouw ticket"
                  onClick={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-[9999]">
          <div className="bg-white p-6 rounded-lg w-80 relative -mt-56">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-gray-600 hover:text-black"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
            <div className="flex justify-center">
              {renderCode(deal, currentCodeIndex)}
            </div>
            {deal.lines.length > 1 && (
              <div className="flex items-center justify-center gap-3 mt-4">
                <button
                  onClick={handlePrevious}
                  disabled={currentCodeIndex === 0}
                  className={`px-4 py-2 rounded-lg ${
                    currentCodeIndex === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-main-color text-white'
                  }`}
                >
                  ←
                </button>
                <p className="text-sm">
                  Code {currentCodeIndex + 1}/{deal.lines.length}
                </p>
                <button
                  onClick={handleNext}
                  disabled={currentCodeIndex === deal.lines.length - 1}
                  className={`px-4 py-2 rounded-lg ${
                    currentCodeIndex === deal.lines.length - 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-main-color text-white'
                  }`}
                >
                  →
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};