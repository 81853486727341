import { createContext, useContext, useState, ReactNode, useEffect, useRef } from 'react';
import { getProfile, getSubscriptions, logout } from '../components/api/API';

interface Address {
  street: string;
  zip: string;
  housenr: string;
  housenrExt: string;
  province: string;
  city: string;
  country: string;
}

interface OptInPreferences {
  emailMarketing: boolean;
  pushNotifications: boolean;
}

interface UserData {
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
  phone: string;
  dateJoined: string; // ISO 8601 format date string
  lastLogin: string | null;
  role: string;
  dateBirth: string; // format "dd-MM-yyyy"
  address: Address;
  optInPreferences: OptInPreferences;
  teacherPlus?: boolean;
}


interface UserContextType {
  user: UserData | null;
  setUser: (user: UserData) => void;
  isAuth: boolean;
  isLoading: boolean;
  fetchUserDetails: () => void;
  handleLogout: () => void;
  subscriptions: any;
  activeSubscription: any;
  refreshSubscriptions: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserData | null>(null)
  const [isAuth, setIsAuth] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [subscriptions, setSubscriptions] = useState([] as any)
  const [activeSubscription, setActiveSubscription] = useState({} as any)

  const intialLoad = useRef(true);
  useEffect(() => {  
    if(intialLoad.current){
      intialLoad.current = false;
      fetchUserDetails() 
    }
  }, [])
 
  const fetchUserDetails = async () => { 
    setLoading(true)
    
    const response = await getProfile(); 
    const data = response.data.data
    if(response.status === 200){
      setIsAuth(true)
      setUser({...data});  
      getUserSubscriptions();
    } else {
      setIsAuth(false)
      setLoading(false)
      console.log('Failed to fetch user details'); 
    }
  };

  const getUserSubscriptions = async () => {
    const response = await getSubscriptions()
    if(response.status === 200){
      let data = response.data
      if(data.data?.length > 0){
        setSubscriptions([...data.data]);
        getActiveSubscription(data.data);
      }
    }
    setTimeout(() => {
      setLoading(false)
    }, 150)
  }
  

  const getActiveSubscription = (allSubscriptions: any) => {
    //find a subscription that is status 'active', has a start date in the past and a end date in the future
    const activeSubscription = allSubscriptions.find((subscription: any) => 
      subscription.status === 'active' && 
      new Date(subscription.startDate) < new Date() && 
      new Date(subscription.endDate) > new Date())
    
    if(activeSubscription){
      setActiveSubscription({...activeSubscription})
    }
  }

  const handleLogout = async () => {
    // Add your logout logic here
    let res = await logout()
    if(res.status === 200){
      setIsAuth(false)
    } 
  }

  const refreshSubscriptions = async () => {
    await getUserSubscriptions()
  }

  return (
    <UserContext.Provider value={{ user, setUser, isAuth, isLoading, fetchUserDetails, handleLogout, subscriptions, activeSubscription, refreshSubscriptions }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  const returnObject = {
    user: context?.user,
    setUser: context?.setUser,
    isAuth: context?.isAuth,
    isLoading: context?.isLoading,
    fetchUserDetails: context?.fetchUserDetails,
    handleLogout: context?.handleLogout,
    subscriptions: context?.subscriptions,
    activeSubscription: context?.activeSubscription,
    refreshSubscriptions: context?.refreshSubscriptions
  }
  return returnObject; 
};
