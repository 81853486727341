import { useEffect, useState } from 'react';
import { CodeInput } from '@components/auth/CodeInput';
import { InputField, SmallButtonYellow } from "component-library";
import { verifyCode, activateSubscription } from "@api/API"; 
import { EmailCheck } from '@regex/EmailCheck'; 
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { useTranslation } from "react-i18next";
import { useNotification } from "@context/Notification/NotificationContext";
import { useSearchParams } from 'react-router-dom';

type CodeActivationProps = {
  userProfile: UserProfile;
  setUserProfile: any;
  handleActivateAccount: () => void;
  parentRequired: boolean;
  loadingObj: any;
};

type UserProfile = {
  code: string;
  firstName: string;
  lastName: string;
  email: string;
  dateBirth: string;
  subscribe: boolean;
  postal: string;
  parentEmail: string;
};

export const CodeActivation: React.FC<CodeActivationProps> = ({ userProfile, setUserProfile, handleActivateAccount, parentRequired, loadingObj }:CodeActivationProps) => {
  const { t } = useTranslation();
  const { notify } = useNotification();
  const [tempCode, setTempCode] = useState('');
  const [isError, setIsError] = useState(false);

  // const [parentRequired, setParentRequired] = useState(true);
  const [searchParams] = useSearchParams();
  const paramCode = searchParams.get('code') || searchParams.get('activation_code');

  useEffect(() => {
    if (paramCode) {
      const cleanedCode = paramCode.replace(/[^a-zA-Z0-9]/g, '').slice(0, 6);
      setTempCode(cleanedCode);
      setUserProfile((prev: UserProfile) => ({ ...prev, code: cleanedCode }));
    }
  }, [paramCode]);

  const updateLoginDetails = (value: string, state: keyof UserProfile) => {
    setUserProfile((prev: UserProfile) => ({
      ...prev,
      [state]: value,
    }));
  };

  const checkRegistrationCode = async () => {
    try {
      const response = await verifyCode(userProfile.code);
      if (response?.status === 200) {
        setIsError(false);
        if (response.data?.data?.schoolMembersObject) {
          setUserProfile((prev: UserProfile) => ({
            ...prev,
            firstName: response.data.data.schoolMembersObject.firstName || prev.firstName,
            lastName: response.data.data.schoolMembersObject.lastName || prev.lastName
          }));
        }
      } else {
        setIsError(true);
        notify(t('activationCodeError'), "error");
      }
    } catch (error) {
      setIsError(true);
      notify(t('activationCodeError'), "error");
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(userProfile?.code?.length > 4){
        checkRegistrationCode();
      }
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [userProfile.code]);

  const updateCode = (value: string) => {
    const cleanedCode = value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 6);
    setTempCode(cleanedCode);
    setUserProfile((prev: UserProfile) => ({ ...prev, code: cleanedCode }));
  };

  const checkDisabled = () => {
    return !(
      userProfile?.code?.length === 6 &&
      !isError &&
      userProfile.firstName.length > 0 &&
      userProfile.lastName.length > 0 &&
      (!parentRequired || (parentRequired && userProfile.parentEmail.length > 0 && EmailCheck(userProfile.parentEmail)))
    );
  };

  // const handleActivateAccount = async () => {
  //   setLoading(true);
  //   try {
  //     const data: any = {
  //       dateOfBirth: userProfile.dateBirth,
  //       email: userProfile.email,
  //       isSubscriber: String(userProfile.subscribe),
  //       postalCode: userProfile.postal,
  //       firstName: userProfile.firstName,
  //       lastName: userProfile.lastName
  //     };
  //     if (parentRequired) {
  //       data['parentEmail'] = userProfile.parentEmail;
  //     }
  //     const response = await activateSubscription(userProfile.code, data);
  //     setLoading(false);
  //     if (response?.status === 200) {
  //       customNavigate('/lidmaatschap/success');
  //     } else {
  //       notify(t('activationFailed'), "error");
  //       console.error('Error activating account:', response);
  //     }
  //   } catch (error) {
  //     console.error('Error during activation:', error);
  //     notify(t('activationFailed'), "error");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <div className="container pt-4">
      <div className="md:flex">
        <div className="mb-6 lg:mb-0">
          <h1 className="font-bold font-formula tracking-wide text-2xl">
            Vul jouw activatiecode in
          </h1>
          <p>Heb je een activatiecode gekregen? Vul de code in om je digitale pas te activeren.</p>
        </div>
        <div className='md:ml-auto mb-8 md:mb-0'>
          <CodeInput
            length={6}
            value={tempCode}
            onChange={updateCode}
            error={userProfile?.code?.length > 0 && isError}
            errorMsg={t('activationCodeError')}
          />
        </div>
      </div>
      {( !isError && tempCode.length > 5 ) && (
        <div className="md:flex gap-x-6">
          <div className="w-full md:w-1/3">
            <InputField
              placeholder={t('firstName')}
              value={userProfile.firstName}
              setState={() => {}}
              type="text"
              error={userProfile?.firstName?.length > 0 && userProfile?.firstName?.length < 2}
              handleUpdate={(value: string, itemKey: string) => updateLoginDetails(value, itemKey as keyof UserProfile)}
              itemKey={'firstName'}
              darkMode={false}
            />
          </div>
          <div className="w-full md:w-1/3">
            <InputField
              placeholder={t('lastName')}
              value={userProfile.lastName}
              setState={() => {}}
              type="text"
              error={false}
              handleUpdate={(value: string, itemKey: string) => updateLoginDetails(value, itemKey as keyof UserProfile)}
              itemKey={'lastName'}
              darkMode={false}
            />
          </div>
          {parentRequired && (
            <div className="w-full md:w-1/3">
              <InputField
                placeholder={t("parentEmail")}
                value={userProfile.parentEmail}
                setState={() => {}}
                type="text"
                error={userProfile.parentEmail.length > 0 && !EmailCheck(userProfile.parentEmail)}
                handleUpdate={(value: string, itemKey: string) => updateLoginDetails(value, itemKey as keyof UserProfile)}
                itemKey={'parentEmail'}
                darkMode={false}
                errorMsg={t("emailError")}
              />
              <div className="text-sm text-gray-500 pt-3">
                {t('parentEmailExplanation')}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="flex justify-center mt-4">
        <div className="w-80">
          <SmallButtonYellow
            text="Activeer mijn digitale pas"
            onClick={handleActivateAccount}
            disabled={checkDisabled()}
            loading={loadingObj.activation}
          />
        </div>
      </div>
    </div>
  );
};