import { Routes, Route, Navigate } from "react-router-dom";
import AuthModal from './components/auth/AuthModal';
import Account from "./pages/Account";
import SubscriptionModal from "@components/auth/SubscriptionModal";
import PasswordModal from "@components/auth/PasswordModal";
import { MyAccount } from "./pages/MyAccount";
import { MySubscriptions } from "./pages/MySubscriptions";
import { MyProfile } from "./pages/MyProfile";
import { MyDeals } from "./pages/MyDeals";
import { MyTicketPage } from "./pages/MyTicketPage";
// import { PrivateRoute } from "./pages/PrivateRoute";
import { UserProvider } from "@context/UserContext";
import { MySettings } from "@components/mijn/MySettings";

function App() {
  return (
    <UserProvider>
      <div className='font-roboto'>
        <Routes>
          <Route path="/:modal" element={<AuthModal />} />
          <Route path="/lidmaatschap/" element={<SubscriptionModal />} />
          <Route path="/lidmaatschap/:modal" element={<SubscriptionModal />} />
          <Route path="/wachtwoord/:modal" element={<PasswordModal />} />
          <Route path="/wachtwoord" element={<PasswordModal />} />
          <Route path="/account" element={<Account />} />
          <Route path="/r/:modal" element={<AuthModal />} />
          <Route path="/r/lidmaatschap/" element={<SubscriptionModal />} />
          <Route path="/r/lidmaatschap/:modal" element={<SubscriptionModal />} />
          <Route path="/r/wachtwoord/:modal" element={<PasswordModal />} />
          <Route path="/r/wachtwoord" element={<PasswordModal />} />
          <Route path="/r/account" element={<Account />} />
          <Route path="*" element={<Navigate to="/inloggen" replace />} />

          {/* <Route element={<PrivateRoute />}> */}
          {/* YEVTODO: decide with robin on actual route names, e.g. 'mijn-profiel'. */}
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/subscriptions" element={<MySubscriptions />} />
          <Route path="/my-profile/my-details" element={<MyProfile />} />
          <Route path="/my-profile/instellingen" element={<MySettings />} />
          <Route path="/my-deals/active" element={<MyDeals />} />
          <Route path="/my-deals/inactive" element={<MyDeals />} />
          <Route path="/my-deals/ticket/:dealId" element={<MyTicketPage />} />
          {/* </Route> */}
        </Routes>
      </div>
    </UserProvider>
  );
}

export default App;